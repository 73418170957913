<template>
  <div v-if="id !== 'all'">
    <div class="flix-form-group">
      <appointmentTime
        :date="date"
        :callback="
          (ret) => {
            setDate(ret)
            setSerialKey()
          }
        "
      />
    </div>
    <div class="flix-form-group">
      <dateInfo v-if="date" :date="date" :key="JSON.stringify(date)" />
    </div>
    <div class="flix-alert flix-alert-danger" v-if="error">
      {{ $t('message.pleaseCheckAppointmentDate') }}
    </div>
    <div v-else>
      <div class="flix-form-group">
        <serial
          :key="serialKey"
          :date="date"
          :callback="
            (s) => {
              serial = s
            }
          "
        />
      </div>
      <div class="flix-form-group">
        <check
          :id="$route.params.id"
          :key="getSerialKey()"
          :date="date"
          :serial="serial"
        />
      </div>
      <assistentForm
        :callback="
          (d, a) => {
            setData(d, a)
          }
        "
        v-if="variables"
        :user="variables.user.md5_id"
        :id="id"
      />
      <div class="flix-form-group">
        <label class="flix-html-label" for="note"
          ><flixIcon id="wrench" />
          {{ $t('message.add', { name: $tc('message.note', 1) }) }}</label
        >
        <input
          id="note"
          type="text"
          class="flix-form-control"
          v-model="data[$tc('message.note', 1)]"
          :placeholder="$t('message.optional')"
        />
      </div>
      <div class="flix-form-group">
        <saveBtn
          :onClick="
            () => {
              setNewBooking()
            }
          "
        >
          <flixIcon slot="icon" class="icon" :id="'pen'" />
          <span slot="text"
            >{{ $getUserVariables().user.username }}<br /><b>{{
              $t('message.save', { name: $tc('message.customer', 1) })
            }}</b></span
          >
        </saveBtn>
      </div>
      <b-flix-alert variant="info">
        <div
          v-html="
            $t('message.customerByUser', {
              icon: '<i class=\'flix-glyphicon icon flix-glyphicon-pen\'></i>'
            })
          "
        ></div>
      </b-flix-alert>
      <transition name="flixFadeIn">
        <div class="flix-form-group" v-if="validation" :key="validation">
          <div class="flix-alert flix-alert-info">{{ validation }}</div>
        </div>
      </transition>
      <transition name="flixFadeIn">
        <div class="flix-form-group" v-if="saved" :key="saved">
          <div class="flix-alert flix-alert-success">
            {{ $t('message.successfullSaved') }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    check() {
      return import('@/components/crm/addCustomer/check')
    },
    appointmentTime() {
      return import('@/components/crm/addCustomer/time')
    },
    assistentForm() {
      return import('@/components/crm/addCustomer/assistentForm')
    },
    serial() {
      return import('@/components/crm/addCustomer/serial')
    },
    dateInfo() {
      return import('@/components/crm/addCustomer/dateInfo')
    }
  },
  props: {
    id: [String, Number]
  },
  data() {
    var d = new Date()
    d.setHours(d.getHours() + 1)
    if (typeof this.$store.getters.calendarDate === 'object') {
      d = this.$store.getters.calendarDate
      d.setHours(14)
    }
    d = {
      begin:
        d.getFullYear() +
        '-' +
        (d.getMonth() + 1) +
        '-' +
        d.getDate() +
        ' ' +
        d.getHours() +
        ':00',
      end:
        d.getFullYear() +
        '-' +
        (d.getMonth() + 1) +
        '-' +
        d.getDate() +
        ' ' +
        d.getHours() +
        ':00'
    }

    d = this.setBooking(d)

    var data = {}
    data[this.$tc('message.note', 1)] = ''

    return {
      openEmail: false,
      error: false,
      date: d,
      data: data,
      email: '',
      tel: '',
      variables: false,
      serial: false,
      serialKey: 0,
      save: {
        begin: {},
        end: {}
      },
      saved: false,
      validation: false
    }
  },
  methods: {
    getSerialKey() {
      return JSON.stringify(this.serial) + this.serialKey
    },
    setSerialKey() {
      this.serialKey = new Date().getTime()
    },
    setData(d, a) {
      this.data = Object.assign(this.data, d)
      a.forEach(
        function (part) {
          if (part.subtype === 'email') {
            Object.keys(d).forEach(
              function (b) {
                if (b === part.label) {
                  this.email = d[b]
                }
              }.bind(this)
            )
          }
          if (part.subtype === 'tel') {
            Object.keys(d).forEach(
              function (b) {
                if (b === part.label) {
                  this.tel = d[b]
                }
              }.bind(this)
            )
          }
        }.bind(this)
      )
    },
    setSaveBooking(data, callback) {
      var post = {
        ID: this.id,
        user: this.variables.user.md5_id,
        admin: this.variables.user.md5_id,
        email: this.email,
        tel: this.tel,
        data: this.data,
        begin: data.begin,
        end: data.end
      }
      this.$flix_post({
        data: post,
        url: 'booking/save',
        callback: function (ret) {
          this.setStoreBooking(post, ret.data[2], function () {
            callback(data)
          })

          if (this.email) {
            post.ID = ret.data[2]
            // this.sendEmail(post)
          }
        }.bind(this)
      })
    },
    setStoreBooking(data, newID, callback) {
      this.$flix_post({
        url: 'booking/get_by_id',
        data: {
          user: data.admin,
          ID: newID
        },
        callback: function (ret) {
          this.$store.commit('addBooking', {
            form: this.id,
            data: ret.data[1][0]
          })
          callback()
        }.bind(this)
      })
    },
    setSaveBookingCallback() {
      this.saved = true
      setTimeout(
        function () {
          this.saved = false
        }.bind(this),
        5000
      )
    },
    setNewBooking() {
      if (!this.serial) {
        this.setSaveBooking(
          this.save,
          function () {
            this.setSaveBookingCallback()
          }.bind(this)
        )
        return false
      }
      this.email = ''
      this.serial.forEach(
        function (date) {
          this.setSaveBooking(
            date,
            function (d) {
              if (this.checkEndDate(d)) {
                this.setSaveBookingCallback()
              }
            }.bind(this)
          )
        }.bind(this)
      )
    },
    checkEndDate(d) {
      if (d.begin.Y !== this.serial[this.serial.length - 1].begin.Y) {
        return false
      }
      if (d.begin.m !== this.serial[this.serial.length - 1].begin.m) {
        return false
      }
      if (d.begin.d !== this.serial[this.serial.length - 1].begin.d) {
        return false
      }
      if (d.begin.H !== this.serial[this.serial.length - 1].begin.H) {
        return false
      }
      if (d.begin.i !== this.serial[this.serial.length - 1].begin.i) {
        return false
      }
      return true
    },
    sendEmail(ret) {
      this.$flix_post({
        url: 'booking/validation_mail',
        data: ret,
        callback: function (ret2) {
          this.validation = this.$t('message.validationLinkSendedTo', {
            name: ret.email
          })
          setTimeout(
            function () {
              this.validation = false
            }.bind(this),
            5000
          )
        }.bind(this)
      })
    },
    setDate(date) {
      this.error = false
      var c = [
        new Date(
          date[0][0][0],
          date[0][0][1] - 1,
          date[0][0][2],
          date[0][1][0],
          date[0][1][1]
        ),
        new Date(
          date[1][0][0],
          date[1][0][1] - 1,
          date[1][0][2],
          date[1][1][0],
          date[1][1][1]
        )
      ]

      if (c[0].getTime() > c[1].getTime()) {
        this.error = true
        return false
      }
      this.date.begin =
        c[0].getFullYear() +
        '-' +
        this.$getNullsBefore(c[0].getMonth() + 1) +
        '-' +
        this.$getNullsBefore(c[0].getDate()) +
        ' ' +
        this.$getNullsBefore(c[0].getHours()) +
        ':' +
        this.$getNullsBefore(c[0].getMinutes())
      this.date.end =
        c[1].getFullYear() +
        '-' +
        this.$getNullsBefore(c[1].getMonth() + 1) +
        '-' +
        this.$getNullsBefore(c[1].getDate()) +
        ' ' +
        this.$getNullsBefore(c[1].getHours()) +
        ':' +
        this.$getNullsBefore(c[1].getMinutes())
      this.save.begin = {
        Y: c[0].getFullYear(),
        m: this.$getNullsBefore(c[0].getMonth() + 1),
        d: this.$getNullsBefore(c[0].getDate()),
        H: this.$getNullsBefore(c[0].getHours()),
        i: this.$getNullsBefore(c[0].getMinutes())
      }
      this.save.end = {
        Y: c[1].getFullYear(),
        m: this.$getNullsBefore(c[1].getMonth() + 1),
        d: this.$getNullsBefore(c[1].getDate()),
        H: this.$getNullsBefore(c[1].getHours()),
        i: this.$getNullsBefore(c[1].getMinutes())
      }
    },
    setBooking(d) {
      if (!this.$store.getters.newBooking) {
        return d
      }
      var b = this.$store.getters.newBooking

      var r = {}
      r.begin =
        b.date.getFullYear() +
        '-' +
        (b.date.getMonth() + 1) +
        '-' +
        b.date.getDate() +
        ' ' +
        b.hour +
        ':' +
        b.minutes
      r.end = JSON.parse(JSON.stringify(r.begin))
      return r
    }
  },
  mounted() {
    this.variables = this.$getUserVariables()
    // this.email = this.variables.user.email
  },
  computed: {}
}
</script>
<style lang="sass" scoped></style>
