var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.id !== 'all')?_c('div',[_c('div',{staticClass:"flix-form-group"},[_c('appointmentTime',{attrs:{"date":_vm.date,"callback":(ret) => {
          _vm.setDate(ret)
          _vm.setSerialKey()
        }}})],1),_c('div',{staticClass:"flix-form-group"},[(_vm.date)?_c('dateInfo',{key:JSON.stringify(_vm.date),attrs:{"date":_vm.date}}):_vm._e()],1),(_vm.error)?_c('div',{staticClass:"flix-alert flix-alert-danger"},[_vm._v(" "+_vm._s(_vm.$t('message.pleaseCheckAppointmentDate'))+" ")]):_c('div',[_c('div',{staticClass:"flix-form-group"},[_c('serial',{key:_vm.serialKey,attrs:{"date":_vm.date,"callback":(s) => {
            _vm.serial = s
          }}})],1),_c('div',{staticClass:"flix-form-group"},[_c('check',{key:_vm.getSerialKey(),attrs:{"id":_vm.$route.params.id,"date":_vm.date,"serial":_vm.serial}})],1),(_vm.variables)?_c('assistentForm',{attrs:{"callback":(d, a) => {
          _vm.setData(d, a)
        },"user":_vm.variables.user.md5_id,"id":_vm.id}}):_vm._e(),_c('div',{staticClass:"flix-form-group"},[_c('label',{staticClass:"flix-html-label",attrs:{"for":"note"}},[_c('flixIcon',{attrs:{"id":"wrench"}}),_vm._v(" "+_vm._s(_vm.$t('message.add', { name: _vm.$tc('message.note', 1) })))],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data[_vm.$tc('message.note', 1)]),expression:"data[$tc('message.note', 1)]"}],staticClass:"flix-form-control",attrs:{"id":"note","type":"text","placeholder":_vm.$t('message.optional')},domProps:{"value":(_vm.data[_vm.$tc('message.note', 1)])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, _vm.$tc('message.note', 1), $event.target.value)}}})]),_c('div',{staticClass:"flix-form-group"},[_c('saveBtn',{attrs:{"onClick":() => {
            _vm.setNewBooking()
          }}},[_c('flixIcon',{staticClass:"icon",attrs:{"slot":"icon","id":'pen'},slot:"icon"}),_c('span',{attrs:{"slot":"text"},slot:"text"},[_vm._v(_vm._s(_vm.$getUserVariables().user.username)),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$t('message.save', { name: _vm.$tc('message.customer', 1) })))])])],1)],1),_c('b-flix-alert',{attrs:{"variant":"info"}},[_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('message.customerByUser', {
            icon: '<i class=\'flix-glyphicon icon flix-glyphicon-pen\'></i>'
          })
        )}})]),_c('transition',{attrs:{"name":"flixFadeIn"}},[(_vm.validation)?_c('div',{key:_vm.validation,staticClass:"flix-form-group"},[_c('div',{staticClass:"flix-alert flix-alert-info"},[_vm._v(_vm._s(_vm.validation))])]):_vm._e()]),_c('transition',{attrs:{"name":"flixFadeIn"}},[(_vm.saved)?_c('div',{key:_vm.saved,staticClass:"flix-form-group"},[_c('div',{staticClass:"flix-alert flix-alert-success"},[_vm._v(" "+_vm._s(_vm.$t('message.successfullSaved'))+" ")])]):_vm._e()])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }